import { buildService } from '@rd-web-markets/shared/dist/services/service';
import serviceMethods from '@rd-web-markets/shared/dist/services/service_methods';
import { useCallback } from 'react';
const api = buildService('/companies/{id}/projects', {
  only: ['all']
});
const useFetchCompanyProjects = (companyId, setLoading) => {
  const [companyProjects, setCompanyProjects, fetchProjects] = serviceMethods.useFetch({
    callback: useCallback(async queryParams => await api.all(companyId, queryParams), [companyId]),
    setLoading
  });
  return [companyProjects, setCompanyProjects, fetchProjects];
};
const companyProjectsService = {
  useFetchCompanyProjects
};
export default companyProjectsService;