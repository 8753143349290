import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Breadcrumb, Row, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { CompanyService } from '@services/company.service';
import claimTemplateCategoriesService from '@services/claim_template_categories.service';
import { Loading } from '@rd-web-markets/shared/dist/util';
import { SHOW_CLAIM_GROUP_TASKS } from '@rd-web-markets/market/dist/constants';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';
import NoClaimsPage from '@rd-web-markets/shared/dist/claim_group/NoClaimsPage';
import ClaimGroupMasterPageDashboard from '@rd-web-markets/shared/dist/dashboards/dashboards/ClaimGroupMasterPageDashboard';
import {
  SHOW_ADD_CLAIM_GROUP_BTN_ON_MASTER_PAGE,
  SHOW_COST_MENU_IN_MASTER,
  SHOW_CLAIM_GROUPS_SELECTOR
} from '@rd-web-markets/market/dist/constants';
import { ClaimState } from '@rd-web-markets/market/dist/claim';
import ProjectTaskState from '@rd-web-markets/shared/dist/claim_group/ProjectTaskState';
import claimTemplateTasksService from '@rd-web-markets/shared/dist/services/claim_groups/claim_template_tasks.service';
import getClaimGroupsService from '@rd-web-markets/shared/dist/services/claim_groups/getClaimGroups.service';
import useRecentVisits from '@rd-web-markets/shared/dist/hooks/useRecentVisits';
import ClaimGroupSidebar from '@rd-web-markets/shared/dist/pages/setup/ClaimGroupSidebar';
import SidebarPortal from '@rd-web-markets/shared/dist/util/SidebarPortal';
import ClaimGroupNavigation from './ClaimGroupNavigation';

const ClaimGroupMasterPage = ({ handleToaster, accountType }) => {
  const { company_id, claim_group_id } = useParams();
  const [company, setCompany] = useState(null);
  const [claimGroup, setClaimGroup] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [allClaimGroups, setAllClaimGroups] = useState([]);
  const { addRecentClaimGroupId, addRecentCompanyId } = useRecentVisits();
  const currentYear = (new Date()).getFullYear().toString()

  const getAllClaimGroups = useErrorHandling(useCallback(async (currentYear) => {
    const allClaimGroups = await getClaimGroupsService.all(company_id);

    const currentYearClaimGroup = allClaimGroups.find(cg => cg.name.includes(currentYear))
    const defaultSelectedClaimGroup = process.env.PUBLIC_URL === '/be' ? currentYearClaimGroup : allClaimGroups[0]

    const claimGroup = allClaimGroups.find(c => c.id === Number(claim_group_id)) || defaultSelectedClaimGroup || allClaimGroups[0];
    setClaimGroup(claimGroup);
    setAllClaimGroups(allClaimGroups);
    claimGroup && addRecentClaimGroupId(String(claimGroup.id));
  }, [company_id, claim_group_id, addRecentClaimGroupId]));

  const resetCompany = useErrorHandling(useCallback(async () => {
    const company = await CompanyService.get(company_id);
    setCompany(company);
    return company;
  }, [company_id]));

  const resetCompanyAndClaimGroup = useErrorHandling(useCallback(async () => {
    await resetCompany();
  }, [resetCompany]));

  const updateClaimGroupTask = claimTemplateTasksService.useUpdateClaimGroupTask({ claimGroup, setClaimGroup, setLoading, handleToaster });

  const markTaskComplete = useErrorHandling(useCallback(async (category, categoryIndex, taskIndex, claimGroup) => { //claimGroup task
    setLoading(true);
    const response = await claimTemplateCategoriesService.update(claimGroup.id, category, categoryIndex, taskIndex);
    setClaimGroup({...claimGroup, tasks: response.tasks });
    handleToaster('Task Updated!');
    setLoading(false);
  }, [handleToaster]));

  useEffect(() => {
    getAllClaimGroups(currentYear);
    resetCompanyAndClaimGroup(company_id, claim_group_id);
    addRecentCompanyId(company_id);
    // eslint-disable-next-line
  }, [company_id, claim_group_id, resetCompanyAndClaimGroup]);

  if(!company) return <Loading />;

  if(!company || !allClaimGroups.length) return <Loading />;

  // This three blocks here only before we present new claim group master page for other markets
  let menuItems = company && allClaimGroups.sort((a, b) => a.name.slice(-4) > b.name.slice(-4) ? -1 : 1).map(claimGroup => {
    // claimGroup.name to replaced to get the year values only
    return { 
      text: claimGroup.name.replace(company.name, '').trim(), 
      link: process.env.PUBLIC_URL !== '/be' ? `claim_groups/${claimGroup.id}/project_overview` : `claim_groups/${claimGroup.id}/edit/general`
    }
  })

  if ( process.env.PUBLIC_URL === '/be' && menuItems.some(item => item.text.valueOf() === currentYear.valueOf())) {
    const currentYearItem = menuItems.find(item => item.text.valueOf() === currentYear.valueOf())
    menuItems = menuItems.filter(item => item.text.valueOf() !== currentYear.valueOf());
    menuItems.reverse()
    menuItems.unshift(currentYearItem)
  }

  if (SHOW_COST_MENU_IN_MASTER && company) {
    menuItems.unshift({ text: 'bfsz', isTitle: true })
    menuItems.push({ text: 'elster', isTitle: true })
    menuItems = menuItems.concat(
      allClaimGroups.sort((a, b) => a.name.slice(-4) > b.name.slice(-4) ? -1 : 1).map(claimGroup => {
        // claimGroup.name to replaced to get the year values only
        return { text: claimGroup.name.replace(company.name, '').trim(), link: `companies/${company.id}/${claimGroup.id}/claim_costs` }
      })
    )
  }

  return (
    <>
      {
        process.env.PUBLIC_URL === '/uk'
         ? <ClaimGroupSidebar
              claimGroup={claimGroup}
              company={company}
              claims={company.claims}
              accountType={accountType}
              highlightedMenuItem={'master'}
            />
          : <SidebarPortal
              menuItems={menuItems}
              headerItem={{ link: '', text: 'home' }}
              company={company}
              accountType={accountType}
              claimGroup={claimGroup}
          />
      }


      <BreadcrumbsPortal>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/${accountType}` }}>
            {t('home')}
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/${accountType}/companies/` }}>
            {t('companies')}
          </Breadcrumb.Item>
          <Breadcrumb.Item active>{company.name}</Breadcrumb.Item>
        </Breadcrumb>
      </BreadcrumbsPortal>

      <div className='d-flex justify-content-between'>
        <div className='d-flex'>
          <h1 className='text-primary mb-4'>
            {claimGroup.name}
          </h1>

          <Button className='ml-4 h-75' size='lg' variant='primary' as={Link} to={`/${accountType}/companies/${company.id}/edit`}>
            <span className='material-icons'>edit</span>
          </Button>

          {company.risk == 'high' &&
            <div className='ml-2'>
              <OverlayTrigger placement="right" overlay={<Tooltip>{t('company_sic_code_is_high_risk')}</Tooltip>}>
                <span className='material-icons' style={{ color: '#DCA90E' }}>warning</span>
              </OverlayTrigger>
            </div>
          }
        </div>
       
        { SHOW_ADD_CLAIM_GROUP_BTN_ON_MASTER_PAGE &&
          <Button variant='text' className='h-50' size='lg' as={Link} to={`/${accountType}/companies/add_claim_group/${company.id_in_provider_system}--${company.number}/companies/${company.id}`}>
            <span className='material-icons md-18'>add</span> {t('add')} {t('claim_group')}
          </Button>
        }

      </div>

      { !claimGroup &&
        <NoClaimsPage company={company} accountType={accountType}/>
      }

      { !!claimGroup &&
        <>
        {
          // Add this before we release new claim group master page for other markets
          !SHOW_CLAIM_GROUPS_SELECTOR &&
          <ClaimGroupNavigation
            claimGroups={allClaimGroups}
            claim_group_id={claim_group_id}
            accountType={accountType}
            company={company}
            claimGroupId={claimGroup.id}
          />
        }
          <Row className='justify-content-end mb-1 mt-3'></Row>

          {!!claimGroup.tasks.length && SHOW_CLAIM_GROUP_TASKS &&
            <>
              <ClaimState tasks={claimGroup.tasks} markTaskComplete={markTaskComplete} updateClaimGroupTask={updateClaimGroupTask} claimGroup={claimGroup} accountType={accountType} />
              <div className='mt-3 border-bottom border-light' />
            </>
          }
          {
            ['/it', '/de'].includes(process.env.PUBLIC_URL) &&
            <ProjectTaskState accountType={accountType} claimGroup={claimGroup} handleToaster={handleToaster} />
          }

          { !['/it', '/de', '/be'].includes(process.env.PUBLIC_URL) &&
            <ClaimGroupMasterPageDashboard
              companyId={company_id}
              claimGroupId={claim_group_id}
              className='mt-5 mb-5'
            />
          }
        </>
      }
    </>
  );
}

export default ClaimGroupMasterPage;
